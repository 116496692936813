export default {
  "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["::MYGUARD CO.,LTD.::"])},
  "About": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
  "Product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
  "News": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
  "Institute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certified institution"])},
  "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "about": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MYGUARD Co., Ltd. possesses over 25 years of professional experience and is committed to the development of novel cosmetic and beauty care technology over the long term. MYGUARD not only has a long-term partnership with global cosmetic brands and medical dermatology institutes , but acts as the agent and develops skin & hair diagnostic systems, optical instruments and skin care products to evaluate the needs of the customers and provide professional services.</br>Compared to traditional diagnostic system, MYGUARD introduces innovative technology into aesthetic medicine and cosmetology by combining the application of mobile devices, so you could skip complicated steps and create an interactive relation with customers."])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The three core values of MYGUARD:"])},
    "value1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INNOVATION"])},
    "ds1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compared to traditional models, MYGUARD is committed to the development of skin & hair diagnostic systems in the application of novel innovative technology."])},
    "value2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOBILE"])},
    "ds2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By combining the mobile devices, customers can keep track of their health information and benefit from multivariate services and consultations in real-time."])},
    "value3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BEAUTY"])},
    "ds3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your exclusive skin health plans so that “The beauty of tomorrow is visible today”"])}
  },
  "contact": {
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["臺灣"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])},
    "addressV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["桃園市龜山區文興路146號3樓之2"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "country2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上海"])}
  },
  "520x": {
    "introTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skin Diagnostic System"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The OBSERV®520x is made with today’s aesthetic practitioner in mind, and is part of the OBSERV®-family of skin diagnosis devices. 25 years of experience and partnerships with dermatological institutions and aesthetic professionals ensure you state-of-the-art technology to enhance your credibility and expertise. Professionalise your client consultations and enhance your expertise by introducing skin diagnostics into your practice. Based on visual information from the surface layer and skin health insights, clients will recognise the need for professional skin treatments and a new homecare regimen to prevent signs of ageing."])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More features"])},
    "videoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OBSERV video - "])},
    "videoSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To get an impression of the OBSERV"])},
    "daylight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daylight"])},
    "daylightds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The face is illuminated with a natural and soft light coming from all directions. The face and its features are evenly illuminated without shadows or emphasis on particular skin features. This mode provides a clear means for the overall appearance evaluation of the facial skin. Overall skin appearance improvements can easily be monitored, and skin concerns and conditions be compared in daylight and other light modes."])},
    "daylightCN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daylight,Deep wrinkles,Eye bags,Milia,Nasolabial fold lines,Loss of skin firmness"])},
    "cross": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross-polarization"])},
    "crossds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This mode allows you to visualize redness and discolorations in the skin. The view into the skin is obscured by bright light reflection of the surface of the skin. In the cross-polarized mode a special filter suppresses such disturbing surface reflections and allow you to look into the skin."])},
    "crossCN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross-polarization,Skin inflammation,Dark circles pigmented,Rosacea mild,Couperosis,Active acne"])},
    "parrallel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parallel-polarization"])},
    "parrallelds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The parallel-polarised mode enhances the visualization of the skin’s surface textures. In the mode the polarisation filter technology is used in the opposite way; the filters enhance the surface refection revealing the glyphic patterns, pore structures, fine surface textures and wrinkles in great detail while diminishing the visibility of disturbing structures and textures hidden in the deep layers of the skin."])},
    "parrallelCN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parallel-polarization,Deep wrinkles,Depressed scars,Comedones blackheads,Open pores,Very dry skin"])},
    "uv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["True UV"])},
    "uvds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The True UV mode allows you to visualise the hidden skin conditions beneath the skin surface. Invisible UV light is projected into the facial skin. These invisible rays have the capacity to travel into the skin. Inside of the skin, the cells and tissues have the natural capacity to turn these invisible rays into visible light and in this way effectively turn the skin into a human lamp. As the light is generated within the skin itself instead of being projected onto the skin; it almost seems that the skin itself is lighting up as if a torch light is held against the backside of the skin."])},
    "uvCN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["True UV,Melasma/Chloasma,Thin skin,Blemish-acne prone skin,SPF,Dry skin "])},
    "woods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wood’s light"])},
    "woodsds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The wood’s mode is named after the inventor of the original Wood’s lamp, Robert William Wood. In this mode the illumination of Wood’s lamp is replicated. This original lamp did not have the UV purity of which the True UV sources in the OBSERV produces. It actually contains a weak but strongly visible blue component. Though at the time of the original Wood’s lamp this visible blue haze diminished the visibility of the fluorescent light coming out of the skin it still remains to have a strong diagnostic value as the blue light is reflected by skin lipids. As a result a light and pinkish coloured haze appears on areas on which the oil film has distributed."])},
    "woodsCN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wood’s light,Oily Skin,Dry skin"])},
    "pigment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pigmentation"])},
    "pigmentds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skin tone inhomogeneity such as local pigmentation and skin discoloration(or also known hyperpigmentation) are highlighted and are far better distinguishable from the topographical ageing signs such as wrinkles or sagging skin in this picture.<br/>Pigmentation may be due to premature skin aging which is caused by external factors such as unprotected sun exposure but also due to biological factors such as hormonal level changes during puberty, pregnancy(a form of hyperpigmentation known as melasma) or as part of the normal ageing process especially menopause."])},
    "pigmentCN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pigmentation,Skin tone inhomogeneities,Sun damage,Freckles,Dark circles pigmented,Melasma"])},
    "redness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redness"])},
    "rednessds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microvascular structures and inflammation in the dermis become clearly visible in this picture. Increased facial redness can be a significant cosmetic concern for many people, generally indicating skin (hyper)sensitiveness and irritation. Two types of redness are usually distinguished: chronic persistent redness caused by impairment of the skin barrier or caused by vascular conditions and temporary reactive redness. While chronic persistent redness is a skin condition that reflects either a constant inflammatory response of the immune system or a vascular condition, temporary reactive redness is generally caused by irritation, sunburn and associated with itching and discomfort and can be soothed once the cause is removed."])},
    "rednessCN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redness,Acne,Rosacea,Broken capillaries,Couperosis,Spider vein"])},
    "texture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texture"])},
    "textureds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This picture highlights the skin’s surface texture and roughness. Early signs of ageing such as fine lines, wrinkles but also pore structures, bumpy skin, rough patches, acne and scar tissue become more apparent and help to understand the overall skin’s micro-relief providing a good indicator of the quality and underlying structure of the skin tissue."])},
    "textureCN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texture,bumpy skin texture,Deep wrinkles,Comedones blackheads,Open pores,Very dry skin"])},
    "dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["360 Light"])},
    "dynamicds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The patented 360 Light illumination mode of the OBSERV 520x shows the degeneration of soft tissue’s subcutaneous fat, muscles and fascia, and the cranium remodeling on the appearance of the face in high detail."])},
    "dynamicCN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["360 Light,Temporal hollowing,Eye bags,Tear through,Open pores,Nasolabial fold lines"])},
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outstanding European Quality"])},
    "description1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Headquartered in Europe, Sylton diagnostics systems develops and produces innovative skin diagnosis devices of outstanding quality.With more than 25 years of experience and partnerships with dermatological institutions and other healthcare professionals all over the world, customers always benefit from the latest imaging technology and high-quality services."])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The 5 benefits of a skin diagnosis<br/>with the OBSERV:"])},
    "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OBSERV 520x Support you"])},
    "description3-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enhance every aesthetic consultation"])},
    "description3-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Become recognised for your client service excellence"])},
    "description3-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create ‘skin awareness’ with unbiased proof"])},
    "description3-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strengthen customer loyalty"])},
    "description3-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Increase cross-selling"])},
    "description3-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Differentiate your aesthetic practice from the competition"])},
    "description3-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have before & after photos for expectation management, illustration of aesthetic procedures and marketing"])},
    "title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OBSERV Brochure"])},
    "description4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To get to know more about the OBSERV and its features"])},
    "title5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OBSERV 肌膚旅歷"])},
    "description5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To get to know more about the OBSERV 肌膚旅歷"])},
    "spec1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensions"])},
    "specV1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["32x43x63cm(LxWxH)"])},
    "spec2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])},
    "specV2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11.2kg"])},
    "spec3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Illumination modes"])},
    "specV3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daylight<br/>Cross-polarization<br/>Parallel-polarization<br/>True UV<br/>Wood’s light<br/>Pigmentation<br/>Redness<br/>Surface Texture<br/>360 light"])},
    "spec4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positioning Systems"])},
    "specV4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Face Positioning System，FPS"])},
    "spec5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Illumination technology"])},
    "specV5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patented LED’s and multiple filter technology"])},
    "spec6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power"])},
    "specV6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24Watts"])},
    "spec7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltage"])},
    "specV7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global 110-240V 50-60Hz"])},
    "spec8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plugs"])},
    "specV8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global plug set"])},
    "spec9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timer"])},
    "specV9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic"])},
    "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "institute": {
    "introTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OBSERV® 520x authenticity certified institution"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Originated in Netherlands, OBSERV® is carefully crafted based on 25 years of research and development. Every OBSERV® unit is manufactured in Europe with the highest quality and standards to provide professional services for medical facilities, doctors, and consultants. Please identify the OBSERV® Certificate of Authenticity."])},
    "All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "North": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["North"])},
    "Middle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Middle"])},
    "South": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["South"])},
    "East": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["East"])}
  },
  "Privacy policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy"])},
  "More Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More information"])},
  "See More": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See more"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])}
}