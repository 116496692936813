<template>
  <div class="footer container-sm">
    <p class="my-3">
      Copyright © 2024 Myguard All rights reserved.
    </p>
  </div>
</template>
<script>
export default {
  name: "Footer",
};
</script>
