const banner = {
  "zh-TW": [
    { src: require(`@/assets/images/zh-TW/slide1.jpg`) },
    { src: require(`@/assets/images/zh-TW/slide2.jpg`) },
    { src: require(`@/assets/images/zh-TW/slide3.jpg`) },
    { src: require(`@/assets/images/zh-TW/slide4.jpg`), link: "https://youtu.be/QQX8dlSPZW8" },
    { src: require(`@/assets/images/zh-TW/slide5.jpg`) },
  ],
  "zh-CN": [
    { src: require(`@/assets/images/zh-TW/slide1.jpg`) },
    { src: require(`@/assets/images/zh-TW/slide2.jpg`) },
    { src: require(`@/assets/images/zh-TW/slide3.jpg`) },
    { src: require(`@/assets/images/zh-TW/slide4.jpg`), link: "https://youtu.be/QQX8dlSPZW8" },
    { src: require(`@/assets/images/zh-TW/slide5.jpg`) },
  ],
  en: [
    { src: require(`@/assets/images/zh-TW/slide1.jpg`) },
    { src: require(`@/assets/images/zh-TW/slide2.jpg`) },
    { src: require(`@/assets/images/zh-TW/slide3.jpg`) },
    { src: require(`@/assets/images/zh-TW/slide4.jpg`), link: "https://youtu.be/QQX8dlSPZW8" },
    { src: require(`@/assets/images/zh-TW/slide5.jpg`) },
  ],
}

export default banner;