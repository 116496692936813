<template>
  <div class="home">
    <Carousel :banner="banner[$i18n.locale]"></Carousel>
    <!-- 關於 -->
    <div id="about">
      <div class="container py-5">
        <h1 class="text-start text-gray fw-bold">About</h1>
        <div class="row">
          <div class="col-lg-6 col-sm-10 center">
            <iframe class="w-100" src="https://www.youtube.com/embed/5QOSfGBB3Jw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
          <div class="col-lg-6 col-sm-10 text-start center">
            <p v-html="$t('about.description')"></p>
          </div>
        </div>
      </div>
    </div>
    <!-- 產品 -->
    <div id="product" class="bg-light">
      <div class="container py-5">
        <h1 class="text-start text-gray fw-bold">Product</h1>
        <div class="row justify-content-center">
          <div class="col-auto" v-for="(p, index) in product" :key="index">
            <a :href="'/product/520x'" target="_self">
              <img class="img-sm" :src="p.src"/>
              <br/>
              <a
                class="mt-3 p-2 px-5 btn rounded-pill border border-primary border-2 btn-outline-primary fw-bold"
                :href="'/product/' + p.name"
                >{{ $t("More Info") }} <i class="fa-solid fa-angles-right"></i
              ></a>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- 最新消息 -->
    <div id="news">
      <div class="container py-5">
        <h1 class="text-start text-gray fw-bold">News</h1>
        <NewsSwiper></NewsSwiper>
        <router-link
          class="mt-3 p-2 px-5 btn rounded-pill btn-primary fw-bold"
          to="/news"
        >{{ $t("See More") }}</router-link>
      </div>
    </div>
    <!-- 聯絡我們 -->
    <div id="contact" class="bg-light">
      <div class="container py-5 text-start">
        <img class="mb-3" src="@/assets/logo.png" />
        <p class="fw-bold">TAIWAN</p>
        <p class="fw-bold border-bottom border-2">
          遠京企業股份有限公司<br/>
          MYGUARD CO., LTD.
        </p>
        <p>臺灣桃園市龜山區文興路146號3樓之2</p>
        <p>3F.-2, No.146, Wenxing Rd.,Guishan, Taoyuan City 33377, Taiwan</p>
        <p>TEL: <a href="tel:+886-3327-0080">+886 3 327-0080</a></p>
        <p>EMAIL: <a href="mailto: info@myguard.com">info@myguard.com</a></p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Carousel from "@/components/Carousel.vue";
import NewsSwiper from "@/components/NewsSwiper.vue";
import banner from "@/config/homeBanner.js";
import product from "@/config/product.js";

export default {
  name: "Home",
  components: {
    Carousel,
    NewsSwiper,
  },
  data() {
    return {
      banner: banner,
      product: product
    };
  },
};
</script>

<style lang="scss">
#contact {
  p {
    margin: 0;
  }
}
</style>
