export default {
  "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["::远京企业股份有限公司 MYGUARD CO.,LTD.::"])},
  "About": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于"])},
  "Product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品"])},
  "News": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新消息"])},
  "Institute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["认证诊所"])},
  "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联络我们"])},
  "about": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["远京拥有超过20年的专业技术，多年来致力于开发美容医学的新科技，与全球化妆品牌及皮肤医学机构皆有长期合作的经验，目前代理与开发美容医学检测设备、光学仪器及皮肤护理产品，同时评估客户需求，提供专业服务。<br>挥别传统的检测模式，我们将创新技术导入美容医学的专业，并结合行动装置的应用，省去繁复的步骤，创造客户互动关系。"])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["远京「三大核心价值」：INNOVATION、MOBILE、BEAUTY。"])},
    "value1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INNOVATION"])},
    "ds1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司致力于开发美容医学检测技术，有别于传统的模式，提供创新科技的应用。"])},
    "value2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOBILE"])},
    "ds2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品结合行动装置的运用，即时掌握健康资讯，提供多元化的服务与谘询。"])},
    "value3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BEAUTY"])},
    "ds3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["制定专属于你的美容医学照护计划。让「明日美丽，今日可见」！"])}
  },
  "contact": {
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台湾"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])},
    "addressV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["桃园市龟山区文兴路146号3楼之2"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "country2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上海"])}
  },
  "More Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更多资讯"])},
  "See More": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看更多"])},
  "Privacy policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐私权政策"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])}
}