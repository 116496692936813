export default {
  "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sylton-OBSERV"])},
  "About": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["關於"])},
  "Product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["產品"])},
  "News": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新消息"])},
  "Institute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認證診所"])},
  "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聯絡我們"])},
  "about": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OBSERV為Sylton的產品。Sylton為皮膚檢測及頭皮檢測領域的專業品牌，其所有系列產品由荷蘭的Innofaith美容科學團隊開發生產，並在全球各地為許多美容產業生產檢測儀器，在美容產業相關領域擁有超過25年的經驗,並參與許多跨國企業合作的創造與發展。"])}
  },
  "More Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更多資訊"])},
  "See More": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看更多"])},
  "contact": {
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["臺灣"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])},
    "addressV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["桃園市龜山區文興路146號3樓之2"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "country2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上海"])}
  },
  "Privacy policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隱私權政策"])},
  "520x": {
    "introTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全臉影像分析檢測儀"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OBSERV 520x皮膚檢測儀是OBSERV旗下推出的最新產品，根據當今醫學美容顧問的需求打造而成。具有25年的專業經驗，長期和皮膚科醫師與醫學美容機構合作，保證給您最新的技術，以提升您的服務專業度。引進皮膚檢測儀，讓您的客戶諮詢和服務更加專業。根據您所提供的視覺化資料，清楚分析皮膚問題和膚況，您的客戶將意識到，為了避免出現皮膚老化的痕跡，他們該接受專業的醫美療程、以及採用新的居家護膚法了。"])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看更多功能特色"])},
    "videoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OBSERV影片 - "])},
    "videoSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進一步了解 OBSERV"])},
    "daylight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日光"])},
    "daylightds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日光模式下，自然且柔和的光線，從各個方向照射到臉部，均勻照亮全臉所有細節，不會產生陰影、或特別突出某些部位。此模式能夠清楚地綜合評價臉部皮膚，並輕易地檢測出全臉需要修正的皮膚問題。另外，也能比較在日光模式和其他模式下，皮膚看起來的狀態與問題有何異同。"])},
    "daylightCN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日光,深層皺紋,眼袋,栗粒疹,法令紋,皮膚鬆弛"])},
    "cross": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交叉偏振光"])},
    "crossds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交叉偏振光模式幫助您看見皮膚中的泛紅與膚色不均等現象。通常而言，因為皮膚表層較強的直接反射光，使我們無法清楚地觀測到表皮下方狀況。在此模式下，利用多重偏振光科技，可有效減弱造成干擾的直接反射光，讓您能觀測到皮膚裡層的情形。"])},
    "crossCN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交叉偏振光,皮膚發炎,黑眼圈,玫瑰斑,酒槽,活躍痤瘡"])},
    "parrallel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平行偏振光"])},
    "parrallelds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平行偏振光模式讓皮膚表面紋理看起來更清楚。此模式與交叉偏振光模式使用的多重偏振光科技，原理正好相反；它加強皮膚表面光學反射，讓皮紋、毛孔結構、表面紋理細節和皺紋等，顯現得一清二楚。同時，針對隱藏在深層肌膚中的干擾性結構與紋理，則會降低它們的可見性。"])},
    "parrallelCN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平行偏振光,皺紋,萎縮性疤痕,黑頭粉刺,毛孔粗大,極度乾燥肌"])},
    "uv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["紫外光"])},
    "uvds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["紫外光模式幫助您看見皮膚表面下隱藏的問題。此模式將不可見紫外光線照射進臉部皮膚，並可穿透皮膚表層到下方。皮膚中的細胞和組織，具有將這些不可見光線轉換為可見光線的天然功能，藉此有效地將皮膚變成發光體。這些光線不再是由外注射進入皮膚，而是由皮膚自己產生，看起來幾乎就像皮膚背面被火炬照亮似的。"])},
    "uvCN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["紫外光,肝斑/黃褐斑,皮膚變薄,油性易冒痘肌,SPF,乾燥肌"])},
    "woods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["伍氏光"])},
    "woodsds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["伍氏光模式命名自伍氏燈的發明者 Robert William Wood，此模式複製伍氏燈的照明原理。這款原創燈源並不像OBSERV產品的另一紫外光模式，擁有紫外線光的飽和亮度。反之，它其實帶有微弱但明顯肉眼可見的藍光。雖然在此模式下，這可見的朦朧藍色光減少了皮膚散發出來的螢光，但由於這藍光是由皮脂反射顯示，所以，它仍有很重要的檢測價值。根據此光線和一道粉色薄霧出現的位置，就能得知皮脂膜分布的結果。"])},
    "woodsCN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["伍氏光,油性肌,乾燥肌"])},
    "pigment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["色素分析"])},
    "pigmentds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["膚色不均的問題：局部色素沉著、皮膚變色（又名過度色素沉著），都會被凸顯出來。相較於皮膚紋理相關的老化現象，例如：皺紋或皮膚鬆弛等，更可以明顯看出膚色不均的狀況。<br/>色素沉著可能是過早的皮膚老化，所產生的現象。外在因素，如：皮膚曝曬在陽光下時沒有防護，以及生理因子，如：青春期、孕期（肝斑是過度色素沉著的一種）所導致的賀爾蒙變化、正常老化過程，尤其處於更年期等，都是問題的源頭。"])},
    "pigmentCN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["色素分析,膚色不均,曬傷,雀斑,黑眼圈,肝斑"])},
    "redness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["敏感泛紅分析"])},
    "rednessds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["這張圖片中，微血管結構和皮膚發炎情況清晰可見。許多人都因越發嚴重的臉部皮膚泛紅問題，大多是超級敏感肌和皮膚刺激，造成美妝上很大的困擾。常見泛紅問題有以下兩種：<br/>（一）長期不退的泛紅，因皮膚防禦功能受損或血管問題所致；<br/>（二）暫時的反應性泛紅。<br/>長期不退的泛紅是種皮膚狀態，反應出免疫系統或微血管的反覆發炎情形；暫時性的反應性泛紅常因刺激、曬傷導致，伴隨著發癢和不適感，移除誘發的因子後，狀況就會改善許多。"])},
    "rednessCN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["敏感泛紅分析,痘痘,玫瑰斑,微血管破裂,酒槽,蜘蛛網狀擴張"])},
    "texture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表面紋理"])},
    "textureds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["這張圖重點在於皮膚表面紋理和粗糙。初步的皮膚老化現象：細紋、皺紋，以及毛孔結構、不平整的斑點、痘痘和疤痕組織等，都將顯現得更清楚，方便理解皮膚的基礎紋路，以說明皮膚組織的狀況與裡層的結構。"])},
    "textureCN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表面紋理,皮膚不平整,皺紋,黑頭粉刺,毛孔粗大,極度乾燥肌"])},
    "dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["360度光源"])},
    "dynamicds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["透過反射光從各個方向照射到臉部，用於檢視眼下淚溝、法令紋、木偶紋、臉頰凹陷等皮膚困擾。適用於微整形、注射、填補等專業的醫美療程，達到更清楚準確的效果"])},
    "dynamicCN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["360度光源,夫妻宮凹陷,眼袋,淚溝,毛孔粗大,法令紋"])},
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["創新優異的歐洲品質"])},
    "description1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["總部位於歐洲的Sylton開發並生產創新且高品質的皮膚檢測儀器，擁有超過二十五年的經驗、廣泛的關鍵技術，並與全球化妝品及製藥公司研發部門、皮膚醫學機構及健康照護專業人員合作，使客戶得以持續享有最新的成像技術及高品質服務。"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用OBSERV進行<br/>皮膚分析的五大優勢"])},
    "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OBSERV 520x 幫助您"])},
    "description3-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提升醫學美容諮詢體驗"])},
    "description3-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["打造令人印象深刻的諮詢服務經驗"])},
    "description3-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用科學、公正的證據，使客戶更在意皮膚狀況"])},
    "description3-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["強化客戶的忠誠度"])},
    "description3-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["增加交叉銷售的機會"])},
    "description3-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["從同業競爭中脫穎而出"])},
    "description3-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["透過療程前後對比影像，管理客戶的需求、說明您的醫學美容方法以及產品行銷"])},
    "title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OBSERV 手冊"])},
    "description4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["點擊下方瞭解更多關於OBSERV及其功能"])},
    "title5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OBSERV 肌膚旅歷"])},
    "description5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["點擊下方瞭解更多News"])},
    "spec1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尺寸"])},
    "specV1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["32x43x63cm(長x寬x高)"])},
    "spec2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重量"])},
    "specV2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11.2kg"])},
    "spec3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["照明模式"])},
    "specV3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日光<br/>交叉偏振光<br/>平行偏振光<br/>紫外光<br/>模擬伍氏燈<br/>表面紋理分析<br/>色素分析<br/>敏感泛紅分析<br/>360度光源模式"])},
    "spec4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["定位系統"])},
    "specV4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["專利定位顯示系統"])},
    "spec5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["照明科技"])},
    "specV5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["專業LED光源及多重偏振光科技"])},
    "spec6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電源"])},
    "specV6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24瓦"])},
    "spec7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電壓"])},
    "specV7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全球通用110-240V 50-60Hz"])},
    "spec8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["插頭"])},
    "specV8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全球通用插頭組"])},
    "spec9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["定時器"])},
    "specV9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動"])},
    "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "institute": {
    "introTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OBSERV® 520x原廠認證醫療院所"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OBSERV®來自荷蘭原廠授權，以25年研發技術為基石，精心鑄造而成，每一台OBSERV®皆在歐洲製造，以最高品質與最高標準生產，為醫療院所、醫師與諮詢師們提供專業服務。<br/><br/>至醫療院所請認明OBSERV®原廠認證授權書。"])},
    "All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])},
    "North": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["北部"])},
    "Middle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中部"])},
    "South": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南部"])},
    "East": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["東部"])},
    "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])}
}