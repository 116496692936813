<template>
  <div>
    <component :is="comp"></component>
  </div>
</template>
<script>
// import product from "@/config/product.js";
import { defineAsyncComponent } from "vue";
export default {
  name: "Product",
  computed: {
    comp() {
      return defineAsyncComponent(() =>
        import(`@/components/product/${this.$route.params.id}.vue`)
      );
    },
  },
};
</script>
<style lang=""></style>
