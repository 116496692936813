<template>
  <div class="header">
    <nav id="navbar" class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-sm">
        <div class="navbar-brand p-0">
          <router-link to="/">
            <img src="@/assets/logo.png" />
          </router-link>
          <!-- 切換語言 -->
          <div id="lang" class="d-flex justify-content-center mt-2">
            <div
              class="px-1 border-gray"
              v-for="(lang, i) in langs"
              :key="`Lang${i}`"
              :class="{ 'border-start': i > 0 }"
            >
              <input
                class="form-check-input"
                type="radio"
                name="lang.lang"
                :id="`Lang${i}`"
                :value="lang.lang"
                :checked="$i18n.locale == lang.lang"
                v-model="$i18n.locale"
              />

              <label
                role="button"
                class="form-check-label"
                :class="{ active: $i18n.locale == lang.lang }"
                :for="`Lang${i}`"
              >
                <a v-if="lang.lang == 'zh-CN'" href="https://observ-cn.com">
                  {{ lang.name }}
                </a>
                <span v-else>
                  {{ lang.name }}
                </span>
              </label>
            </div>
          </div>
        </div>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <!-- 各個tab -->
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <li class="nav-item" v-for="(t, index) in tab" :key="index">
              <a :id="`tab-${index}`" class="nav-link" :href="`/${t.to}`">{{
                $t(t.name)
              }}</a>
            </li>
          </ul>
          <!-- 社交平台 -->
          <form class="d-flex center">
            <ul class="navbar-nav social-nav">
              <li class="m-1 center">
                <a href="https://www.facebook.com/observtw/">
                  <!-- <i class="fa-brands fa-square-facebook"></i> -->
                  <img class="w-100 h-100" src="@/assets/images/facebook.png" />
                </a>
              </li>
              <li class="m-1 center">
                <a href="http://nav.cx/ntLNJyv">
                  <!-- <i class="fa-brands fa-line"></i> -->
                  <img class="w-100 h-100" src="@/assets/images/line.png" />
                </a>
              </li>
            </ul>
          </form>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import { lang, tab } from "@/config/headerConfig.js";
export default {
  name: "Header",
  data() {
    return {
      langs: lang,
      tab: tab,
    };
  },
  mounted() {
    window.addEventListener("scroll", function () {
      // 偵測是否固定Header
      let navbar = document.getElementById("navbar");
      if (window.scrollY > navbar.offsetHeight) {
        navbar.classList.add("fixed-top");
        document.body.style.paddingTop = navbar.offsetHeight + "px";
      } else {
        navbar.classList.remove("fixed-top");
        document.body.style.paddingTop = "0";
      }
      if (document.getElementsByClassName("home").length > 0) {
        let active = false;
        // 偵測是否在tab區塊
        tab.forEach((e, i) => {
          let el = document.getElementById(e.to.split("#")[1]);
          let rect = el.getBoundingClientRect();
          let full = rect.top >= 0 && rect.bottom <= window.innerHeight;
          let part = rect.top < window.innerHeight / 2 && rect.bottom >= 0;
          if (full || part) {
            var navLink = document.getElementsByClassName("nav-link");
            for (var j = 0; j < navLink.length; j++) {
              navLink[j].classList.remove("active");
            }
            document.getElementById(`tab-${i}`).classList.add("active");
            active = true;
          }
        });
        if (!active) {
          var navLink = document.getElementsByClassName("nav-link");
          for (var j = 0; j < navLink.length; j++) {
            navLink[j].classList.remove("active");
          }
        }
      }
    });
  },
};
</script>
<style lang="scss">
#lang {
  .border-gray {
    line-height: 0.8;
  }
  label.active {
    color: var(--bs-primary);
    font-weight: 700;
  }
  input[type="radio"] {
    display: none;
  }
  input[type="radio"] + label {
    font-size: 0.9rem;
    a {
      text-decoration: none;
      color: black;
    }
  }
}
.navbar-nav {
  .active {
    color: var(--bs-primary) !important;
    font-weight: bold;
  }
}
.social-nav {
  li {
    width: 30px;
    height: 30px;
  }
}
</style>
